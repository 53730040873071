.Post {
    flex-wrap: wrap;
    overflow-y: scroll;
    min-height: calc(100vh - 72px - 46px);
    background: #081430;
    .TextInfo {
        color: #fff;
        padding: 20px; }
    figcaption {
        padding: 20px; } }
