.Footer {
    height: 70px;
    width: 100%;
    position: sticky;
    bottom: 0px;
    background: #fff;
    border-top: 2px solid black;
    transition: 300ms ease-in-out;
    display: flex;
    .UploadedImg {
        width: 100%;
        top: 50%;
        position: relative;
        transform: translateY(-50%); }
    &.Extended {
        height: 500px;
        .IconWrapper {
            width: 40%;
            position: absolute;
            top: 20px;
            right: 20px;
            display: flex;
            padding: 7px;
            justify-content: space-around;
            font-size: 40px;
            background-color: white;
            border: 2px solid #000;
            align-items: center;
            .Red {
                fill: red; }
            label {
                padding-right: 5px; } } }
    .Upload {
        cursor: pointer; }
    .QRCode {
        width: 100%;
        background-color: #000000;
        padding-top: 50px;
        height: 100%; }
    .MapContainer {
        width: 100%;
        height: 100%; }
    .IconWrapper {
        z-index: 999;
        border-left: 2px solid #000;
        width: 50%;
        display: flex;
        justify-content: space-around;
        font-size: 40px;
        padding: 15px 20px 0 20px;
        span {
            font-size: 20px;
            font-weight: bold;
            text-transform: uppercase;
            color: green; } } }
