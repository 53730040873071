.GeneratedUser {
    table {
        width: 100%;
        border-spacing: 0px;
        tr {
            td {
                border-bottom: 1px solid #000;
                padding: 3px 5px;
                width: 80%;
                box-sizing: border-box;
                text-align: left;
                &:first-child {
                    width: 10%; } }
            &:nth-child(even) {
                td {
                    background-color: #ccc; } } } } }
