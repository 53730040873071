.DrawerToggle {
    width: 40px;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    align-self: flex-end;
    div {
        margin-bottom: 5px;
        width: 90%;
        height: 2px;
        background-color: black; } }
