.GenerateForm {
    padding: 20px;
    input {} }

.GeneratedUsers {
    padding: 20px;
    margin-top: 20px;
    background-color: #ccc;
    table {
        margin-top: 20px;
        width: 100%;
        border-collapse: collapse;
        tr td {
            padding: 4px 2px;
            border-bottom: 1px solid #666; } } }
