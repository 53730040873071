body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 }  //background: #202020

* {
  margin: 0;
  padding: 0; }

h1 {
  font-size: 20px;
  text-align: center;
  width: 100%;
  background: #000000;
  color: #ffffff;
  border-bottom: 2px solid #fff;
  text-transform: uppercase;
  padding: 5px;
  box-sizing: border-box; }

h3 {
  font-size: 13px;
  font-weight: 300;
  text-transform: uppercase;
  padding: 5px 20px; }

.button {
  border: 1px solid #fff;
  background-color: #fff;
  padding: 9px 15px;
  margin: 20px;
  font-weight: bold;
  font-size: 15px;
  border-radius: 4px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; }

.App {
  max-width: 600px;
  margin: 0 auto; }

@media screen AND (min-width: 600px) {
  .App {
    box-shadow: 2px 2px 20px rgba(0,0,0,.4);
    max-width: 600px;
    margin: 0 auto; } }
