.MediaElement {
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    position: relative;
    height: auto;
    background-color: #081430;
    color: #081430;
    color: #fff;
    .ContentBlock {
        padding: 15px 0px;
        p {
            padding: 0 20px; } }
    video {
        width: 100%;
        outline: 0; }
    figure {
        margin-bottom: 20px;
        figcaption {
            padding: 20px 20px 0 20px; } }
    h4 {
        padding-top: 5px;
        text-align: center; }
    p {
        padding: 5px;
        margin-bottom: 20px; }
    img {
        width: 100%;
        display: block; }
    .Thumb {
        height: 100%;
        object-fit: cover; }
    &.MediaOnly {
        padding: 0px !important; }
    .ThumbText {
        height: 100%;
        padding: 7px;
        background: #d0d0d0;
        color: #081430;
        font-weight: bold;
        background-image: radial-gradient(#ffffff 50%, #081430 100%);
        background-image: linear-gradient(-135deg, #ffffff 50%, #081430 100%); }
    &.Extended {
        //border: 1px solid #081430
 }        //box-shadow: 0 0 0 1px #081430

    &.AlwaysExtended {
        border-bottom: 0px; }
    .Close {
        position: absolute;
        z-index: 99;
        font-size: 40px;
        right: 10px;
        top: 10px; } }
