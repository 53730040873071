.AuthForm {
    padding: 20px; }


@keyframes enable {
    0% {
        transform: scale(1); }
    60% {
        transform: scale(1.1); }
    100% {
        transform: scale(1); } }
