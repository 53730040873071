.Finished {
    h1 {
        margin-bottom: 20px; }
    p {
        padding: 0 20px;
        font-size: 20px;
        line-height: 1.2em;
        margin-bottom: 20px; }
    a {
        color: grey; } }
